import { Wallet } from "@phosphor-icons/react";
import { ethers } from "ethers";
import { FormattedNumber } from "react-intl";
import clsx from "clsx";

export function SwapperFieldBalanace({
  token,
  balance,
  exceededBalance,
  maxedBalanace,
  updateValue,
  isSell,
}) {

  return (
    <div className="flex items-center justify-between text-xs text-gray-500">
      <FormattedNumber
        value={ethers.formatUnits(token.price, 6)}
        style="currency"
        currency="USD"
        currencyDisplay="symbol"
      />
      <div className={clsx("flex items-center gap-1.5", (exceededBalance && "text-red-600"))}>
        <Wallet weight="bold" size={10} className="-mb-[1px]" />
        <FormattedNumber value={balance}
          style="decimal"
          minimumFractionDigits={2}
        />
        {isSell && (
          <button
            className={clsx(
              "rounded-full px-2 leading-5",
              (exceededBalance ?
                "bg-red-100 text-red-600" :
                (maxedBalanace ? "bg-gray-100 text-gray-600" : "bg-blue-100 text-blue-600")
              ),
            )}
            onClick={() => updateValue(balance)}
          >
            Max
          </button>
        )}
      </div>
    </div>
  );
}
