import { useEffect, useState } from "react";
import { Connector, useConnect } from "wagmi";
import { Button } from "@headlessui/react";

export function WalletConnect() {
  const { connectors, connect } = useConnect();

  return (
    <div class="flex justify-between gap-2">
      {connectors.slice(0).map((connector) => (
        <WalletOption
          key={connector.uid}
          connector={connector}
          onClick={() => connect({ connector })}
        />
      ))}
    </div>
  );
}

function WalletOption({
  connector,
  onClick,
}: {
  connector: Connector
  onClick: () => void
}) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    ;(async () => {
      const provider = await connector.getProvider();
      setReady(!!provider);
    })();
  }, [connector]);

  return (
    <Button className="bg-blue-100 text-blue-600 px-4 py-2 rounded-full text-sm" disabled={!ready} onClick={onClick}>
      {connector.name}
    </Button>
  );
}

