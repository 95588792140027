import { useAccount, useDisconnect } from "wagmi";
import { WalletConnect } from "./WalletConnect";
import { Button } from "@headlessui/react";

export function NavBar() {
  const { isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  return (
    <div className="flex justify-between items-center p-4">
      <div className="text-xl">Swap</div>
      {isConnected ? (
        <Button className="bg-red-100 text-red-600 px-4 py-2 rounded-full text-sm" onClick={() => disconnect()}>
          Disconnect
        </Button>
      ) : (
        <WalletConnect />
      )}
    </div>
  );
}
