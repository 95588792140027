import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { config } from "./wagmin.config";
import { NavBar } from "./NavBar";
import { Swapper } from "./Swapper";

const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <div className="bg-background text-foreground">
          <NavBar />
          <div className="flex flex-col gap-6 lg:gap-12 items-center min-h-screen p-4">
            <h1 className="max-w-sm mx-auto text-4xl lg:text-6xl text-center">
              Swap anytime, anywhere.
            </h1>
            <Swapper />
          </div>
        </div>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
