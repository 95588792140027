import { FixedNumber } from "ethers";
import { useState } from "react";
import { useContractRead } from "wagmi";
import { SwapperField } from "./SwapperField";
import { SwapperToggle } from "./SwapperToggle";
import ORACLE_ABI from "./abis/oracle.json";

const ORACLE_ADDRESS = process.env.REACT_APP_ORACLE_ADDRESS;

export function Swapper() {
  const [tokenA, setTokenA] = useState(null);
  const [tokenB, setTokenB] = useState(null);
  const [valueA, setValueA] = useState("");
  const [valueB, setValueB] = useState("");

  const { data: rate } = useContractRead({
    address: ORACLE_ADDRESS,
    abi: ORACLE_ABI,
    functionName: "getRate",
    args: [tokenA?.address, tokenB?.address, true],
  });

  function updateValueA(value: string) {
    setValueA(value);

    if (value.length > 0 && tokenB) {
      return setValueB(mulUnsafe(value, rate, tokenB.decimals));
    }
    setValueB("");
  }

  function updateValueB(value: string) {
    setValueB(value);

    if (value.length > 0 && tokenA) {
      return setValueA(divUnsafe(value, rate, tokenB.decimals));
    }
    setValueA("");
  }

  function selectTokenA(token: Token) {
    setTokenA(token);

    if (token.address == tokenB?.address) {
      setTokenB(null);
      setValueB("");
    }
  }

  function selectTokenB(token: Token) {
    setTokenB(token);

    if (token.address == tokenA?.address) {
      setTokenA(null);
      setValueA("");
    }
  }

  function toggleTokens() {
    setTokenA(tokenB);
    setTokenB(tokenA);
    setValueA(valueB);
    setValueB(valueA);
  }

  function divUnsafe(str, bn, dec) {
    const fixedStr = FixedNumber.fromString(str);
    const fixedBn = FixedNumber.fromValue(bn, dec);

    return fixedStr.divUnsafe(fixedBn).toString();
  }

  function mulUnsafe(str, bn, dec) {
    const fixedStr = FixedNumber.fromString(str);
    const fixedBn = FixedNumber.fromValue(bn, dec);

    return fixedStr.mulUnsafe(fixedBn).toString();
  }

  return (
    <div className="w-full max-w-lg bg-white p-1 rounded-3xl shadow-2xl shadow-blue-100">
      <SwapperField
        token={tokenA}
        selectToken={selectTokenA}
        value={valueA}
        updateValue={updateValueA}
        isSell={true}
      />
      <SwapperToggle onClick={toggleTokens} />
      <SwapperField
        token={tokenB}
        selectToken={selectTokenB}
        value={valueB}
        updateValue={updateValueB}
      />
      <button className="block w-full bg-blue-100 text-blue-600 mt-1 py-4 rounded-3xl font-medium">
        Swap
      </button>
    </div>
  );
}
