import { useAccount, useReadContract, useReadContracts } from "wagmi";
import SUGAR_ABI from "../abis/sugar.json";
import TOKEN_ABI from "../abis/token.json";
import ORACLE_ABI from "../abis/oracle.json";
import { FixedNumber } from "ethers";

const SUGAR_ADDRESS = process.env.REACT_APP_SUGAR_ADDRESS;
const ORACLE_ADDRESS = process.env.REACT_APP_ORACLE_ADDRESS;
const USDC_ADDRESS = process.env.REACT_APP_USDC_ADDRESS;

export function useTokens() {
  const { address: accountAddress } = useAccount();

  const { data: allTokens = [], isLoading } = useReadContract({
    address: SUGAR_ADDRESS,
    abi: SUGAR_ABI,
    functionName: "tokens",
    args: [2000, 0, accountAddress, []],
  });

  const listedTokens = allTokens.filter((token) => token?.listed);

  const tokenContracts = (token) => {
    const address = token.token_address.toLowerCase();
    const contract = { address, abi: TOKEN_ABI };
    const oracleContract = { address: ORACLE_ADDRESS, abi: ORACLE_ABI };

    return [
      { ...contract, functionName: "name" },
      { ...contract, functionName: "symbol" },
      { ...contract, functionName: "decimals" },
      { ...contract, functionName: "balanceOf", args: [accountAddress] },
      { ...oracleContract, functionName: "getRate", args: [address, USDC_ADDRESS, true] },
    ];
  };

  const { data: tokenProps = [], isLoadingTokens } = useReadContracts({
    contracts: listedTokens.map((token) => tokenContracts(token)).flat(),
    query: { enabled: listedTokens.length > 0 },
  });

  let tokens = groupsOf(tokenProps, 5).map((props, i) => {
    const address = listedTokens[i].token_address.toLowerCase();
    const [name, symbol, decimals, balance, price = 0] = props.map((v) => v?.result);
    return { name, symbol, decimals, balance, address, price };
  });

  tokens = tokens.sort((a, b) => {
    const fixedA = FixedNumber.fromValue(a.balance, a.decimals);
    const fixedB = FixedNumber.fromValue(b.balance, b.decimals);

    if (fixedA.gt(fixedB)) return -1;
    if (fixedA.lt(fixedB)) return 1;
    return 0;
  });

  function groupsOf(array, size) {
    return array.reduce((batchs, _, i) => {
        if (i % size === 0) {
            batchs.push(array.slice(i, i + size));
        }
        return batchs;
    }, []);
  }

  return { tokens, isLoading: isLoading || isLoadingTokens };
}
