import { Img } from "react-image";
import { base } from "wagmi/chains";

const ASSETS_CDN = "https://assets.smold.app/api/token";

export function TokenIcon({ token, ...props }) {
  const icons = [
    `${ASSETS_CDN}/${base.id}/${token.address}/logo.svg`,
    "/token.svg",
  ];
  return (
    <Img {...props} src={icons} />
  );
}
