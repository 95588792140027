import { useEffect, useRef, useState } from "react";
import { useTokens } from "./hooks/useTokens";
import { TokenItem } from "./TokenItem";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { ListMagnifyingGlass, MagnifyingGlass, Spinner } from "@phosphor-icons/react";

export function TokenList({ onClose, onSelect }) {
  const [query, setQuery] = useState("");
  const { tokens, isLoading } = useTokens(true);
  const queryRef = useRef(null);

  const filteredTokens = tokens.filter((token) => (
    token.symbol.toLowerCase().includes(query.toLowerCase())
  ));

  useEffect(() => {
    if (!isLoading) {
      queryRef.current.focus();
    }
  }, [isLoading]);

  return (
    <Dialog open={true} onClose={onClose} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-black/30" />
      <div className="fixed inset-0 flex w-screen items-center justify-center lg:p-4 shadow-2xl overflow-y-auto">
        <DialogPanel className="relative h-full lg:h-2/3 w-full flex flex-col max-w-md bg-white lg:rounded-3xl overflow-hidden">
          <div className="shrink p-4 shadow-sm">
            <div className="grow flex relative">
              <input
                className="grow pl-8 pr-4 leading-10 bg-gray-100 rounded-full
                placeholder:text-gray-400 focus:outline-none"
                type="text"
                placeholder={isLoading ? "Loading tokens, please stand by..." : "Search token.."}
                disabled={isLoading}
                ref={queryRef}
                onChange={(e) => setQuery(e.target.value)}
              />
              <div className="absolute top-0 left-0 bottom-0 flex items-center pl-3 pointer-events-none">
                {isLoading ? (
                  <Spinner weight="bold" size={16} className="animate-spin text-blue-500" />
                ) : (
                  <MagnifyingGlass weight="bold" size={16} />
                )}
              </div>
            </div>
          </div>
          {filteredTokens.length > 0 ? (
            <div className="grow overflow-y-auto">
              {filteredTokens.map((token) => (
                <TokenItem key={token.address} token={token} onSelect={onSelect} />
              ))}
            </div>
          ) : (
            query && (
              <div className="p-12 grow flex flex-col items-center gap-4 text-gray-500">
                <div className="bg-gray-100 p-4 rounded-full">
                  <ListMagnifyingGlass weight="light" size={32} />
                </div>
                <h2 className="font-medium text-center max-w-xs">
                  No results found<br /> for
                  &quot;<span className="text-black">{query}</span>&quot;
                </h2>
              </div>
            )
          )}
        </DialogPanel>
      </div>
    </Dialog>
  );
}
