import { useState } from "react";
import { CaretDown } from "@phosphor-icons/react";
import { TokenList } from "./TokenList";
import clsx from "clsx";
import { TokenIcon } from "./TokenIcon";

export function SwapperFieldToken({
  token,
  selectToken,
}) {
  const [isOpen, setIsOpen] = useState(false);

  function handleSelect(token: Token) {
    selectToken(token);
    setIsOpen(false);
  }

  return (
    <>
      <button
        className={clsx(
          "flex-none flex items-center rounded-full text-sm whitespace-nowrap font-medium gap-2",
          (token ?
            "border border-gray-100 shadow-sm p-1 pr-3" :
            "bg-blue-600 text-white px-3 py-2"
          ),
        )}
        onClick={() => setIsOpen(true)}
      >
        {token && <TokenIcon token={token} className="w-7 rounded-full" />}
        {token ? token.symbol : "Select token"}
        <CaretDown weight="bold" size={14} className="-mx-0.5 hidden lg:block" />
      </button>
      {isOpen && (
        <TokenList
          onClose={() => setIsOpen(false)}
          onSelect={handleSelect}
        />
      )}
    </>
  );
}
