import { FixedNumber, ethers } from "ethers";
import clsx from "clsx";
import { SwapperFieldToken } from "./SwapperFieldToken";
import { SwapperFieldBalanace } from "./SwapperFieldBalanace";

export function SwapperField({
  token,
  selectToken,
  value,
  updateValue,
  isSell = false,
}) {

  const balance = token && ethers.formatUnits(token.balance, token.decimals);
  const fixedValue = value && FixedNumber.fromString(value);
  const fixedBalance = token && FixedNumber.fromValue(token.balance, token.decimals);
  const exceededBalance = token && value && isSell && fixedValue.gt(fixedBalance);
  const maxedBalanace = token && value && fixedValue.eq(fixedBalance);

  function handleChange(event) {
  const value = event.target.value;

  if (value.match(/^\d*\.?\d*$/)) {
    updateValue(value);
  }
}

  return (
    <label className={clsx(
      "flex flex-col gap-2 border border-gray-100 p-6 rounded-3xl",
      (token ? "cursor-text" : "bg-gray-100"),
    )}>
      <div className="text-gray-500">
        {isSell ? "Sell" : "Buy"}
      </div>
      <div className="flex items-center gap-2">
        <input
          className={clsx(
            "w-full shrink font-medium leading-10 bg-transparent",
            "placeholder:text-gray-300 focus:outline-none",
            (exceededBalance && "text-red-600"),
            (value.length > 10 ? "text-2xl" : "text-3xl"),
          )}
          inputMode="decimal"
          placeholder="0"
          value={value}
          disabled={!token}
          onChange={handleChange}
        />
        <SwapperFieldToken
          token={token}
          selectToken={selectToken}
        />
      </div>
      {token && (
        <SwapperFieldBalanace
          token={token}
          balance={balance}
          exceededBalance={exceededBalance}
          maxedBalanace={maxedBalanace}
          updateValue={updateValue}
          isSell={isSell}
        />
      )}
    </label>
  );
}
