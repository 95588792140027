import { CaretDown } from "@phosphor-icons/react";

export function SwapperToggle({ onClick }) {
  return (
    <div className="flex items-center justify-center h-1">
      <button
        className="p-3 rounded-2xl bg-gray-100 border-4 border-white"
        onClick={onClick}
      >
        <CaretDown weight="bold" size={20} />
      </button>
    </div>
  );
}
