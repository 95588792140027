import { useEffect, useRef, useState } from "react";
import { useAccount } from "wagmi";
import { FormattedNumber } from "react-intl";
import { ethers } from "ethers";
import { TokenIcon } from "./TokenIcon";

export function TokenItem({ token, onSelect }) {
  const { address: accountAddress } = useAccount();
  const [inView, setInView] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setInView(true);
      }
    });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return inView ? (
    <div
      className="flex justify-between items-center gap-2 py-4 px-6 hover:bg-gray-50 cursor-pointer"
      onClick={() => onSelect(token)}
    >
      <div className="flex grow items-center gap-2">
        <TokenIcon className="w-8 rounded" token={token} />
        <div className="grow font-medium">
          {token.symbol}
          <div className="truncate w-2/3 text-gray-500 text-xs">
            {token.name}
          </div>
        </div>
      </div>
      {accountAddress && (
        <div className="text-right">
          <FormattedNumber
            value={ethers.formatUnits(token.price, 6)}
            style="currency"
            currency="USD"
            currencyDisplay="symbol"
          />
          <div className="text-gray-500 text-xs">
            <FormattedNumber
              value={ethers.formatUnits(token.balance, token.decimals)}
              style="decimal"
              minimumFractionDigits={2}
            />
          </div>
        </div>
      )}
    </div>
  ) : (
    <div ref={targetRef} className="h-2" />
  );
}
