import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { IntlProvider } from "react-intl";
import App from "./App";
import translations from "./translations.json";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <IntlProvider locale="en" messages={translations}>
      <App />
    </IntlProvider>
  </React.StrictMode>,
);
